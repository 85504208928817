<template>
  <Transition name="fade">
    <div class="LoadingOverlay" v-if="loading">
      <div class="MSpinner">
        <div />
        <div />
      </div>
    </div>
  </Transition>
  <!-- //- .success-checkmark(v-else) //-
  svg.checkmark(xmlns='http://www.w3.org/2000/svg', viewBox='0 0 52 52') //-
  circle.checkmark__circle(cx='26', cy='26', r='25', fill='none') //-
  path.checkmark__check(fill='none', d='M14.1 27.2l7.1 7.2 16.7-16.8') -->
</template>

<script>
export default {
  props: { loading: { type: Boolean, default: true } }
}
</script>

<style lang="scss" scoped>
.LoadingOverlay {
  @include overlay();
  @include flex();
  z-index: 10;
  background: rgba(0, 0, 0, 0.205);
  transition: all 0.5s;
  .MSpinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .MSpinner div {
    position: absolute;
    border: 4px solid rgba(255, 255, 255, 0.63);
    opacity: 1;
    border-radius: 50%;
    animation: MSpinner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .MSpinner div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes MSpinner {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
</style>
