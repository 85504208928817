var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Transition", { attrs: { name: "fade" } }, [
    _vm.loading
      ? _c("div", { staticClass: "LoadingOverlay" }, [
          _c("div", { staticClass: "MSpinner" }, [_c("div"), _c("div")])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }